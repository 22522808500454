<template>
  <div :is="opportunityData === undefined ? 'div' : 'b-card'">
    <contract-add-new
      :is-add-new-contract-sidebar-active.sync="isAddNewContractSidebarActive"
      :project-options="projectOptions"
      :customer-options="customerOptions"
      :team-options="teamOptions"
      :contract-name="opportunityName"
      :contract-description="opportunityDescription"
      :contract-value="opportunityValue"
      :contract-customer="opportunityCustomerId"
      :contract-date="opportunityDate"
      :contract-international="international"
      :contract-international-price="internationalPrice || ''"
    />

    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="opportunityData === undefined">
      <h4 class="alert-heading">
        {{ $t("message.errorLoadingData") }}
      </h4>
      <div class="alert-body">
        {{ $t("message.opportunities.opportunityNotFound") }}
        <b-link class="alert-link" :to="{ name: 'apps-opportunity-list' }">
          {{ $t("message.opportunities.opportunityList") }}
        </b-link>
        {{ $t("message.opportunities.forOtheropportunities") }}
      </div>
    </b-alert>

    <template v-if="opportunityData">
      <b-row class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="12" md="12">
          <b-dropdown
            id="dropdown-1"
            class="text-body h4 float-right"
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="cursor-pointer"
              />
            </template>
            <b-dropdown-item
              v-if="restrictions('button_edit_opportunity')"
              @click="
                $router.push({
                  name: 'apps-opportunities-edit',
                  params: { id: opportunityData.id },
                })
              "
              >{{ $t("message.buttons.edit") }}</b-dropdown-item
            >
            <b-dropdown-item
              v-if="restrictions('button_delete_opportunity')"
              v-b-modal.modal-delete-opportunity-confirmation
              >{{ $t("message.buttons.delete") }}</b-dropdown-item
            >
          </b-dropdown>
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                    <h3 class="text-primary invoice-logo">BRXMIND</h3>
                  </div>
                  <p class="card-text mb-25">
                    Avenida Sagitário, nº 138 - Sala 409
                  </p>
                  <p class="card-text mb-25">
                    Alphaville, Barueri - CEP: 06473-073
                  </p>
                  <p class="card-text mb-0">+55 11 3090-6629</p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2" style="max-width: 800px">
                  <h4 class="invoice-title">
                    #
                    <span class="invoice-number">{{
                      opportunityData.name
                    }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ $t("message.opportunities.start") }}
                    </p>
                    <p class="invoice-date">
                      {{ localeDate(opportunityData.dateCreation) }}
                    </p>
                  </div>

                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      {{ $t("message.opportunities.end") }}
                    </p>
                    <p class="invoice-date">
                      {{ localeDate(opportunityData.dateConclusion) }}
                    </p>
                  </div>

                  <br />
                </div>
              </div>
              <div>
                <p class="invoice-date-title">
                  {{ $t("message.opportunities.description") }}
                </p>
                <p>
                  <span v-html="opportunityData.description"></span>
                </p>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body v-if="customerData" class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="p-0">
                  <h6 class="mb-2">{{ $t("message.contract.client") }}:</h6>
                  <h6 class="mb-25">
                    {{ customerData.name }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ customerData.address }}
                  </p>
                  <p class="card-text mb-25">
                    {{ customerData.city }},
                    {{ customerData.district }}
                  </p>
                  <p class="card-text mb-25">
                    {{ customerEmails }}
                  </p>
                  <p class="card-text mb-0">
                    {{ customerPhones }}
                  </p>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6 class="mb-2">{{ $t("message.contract.details") }}:</h6>
                    <table>
                      <tbody>
                        <tr v-if="!opportunityData.international">
                          <td class="pr-1">
                            {{ $t("message.contract.totalValue") }}:
                          </td>
                          <td>
                            {{ $n(opportunityData.value, "currency") }}
                          </td>
                        </tr>
                        <tr v-else>
                          <td class="pr-1">
                            {{ $t("message.contract.totalValue") }}:
                          </td>
                          <td>
                            {{ opportunityData.value }}
                          </td>
                        </tr>
                        <br />

                        <tr>
                          <td class="pr-1">
                            {{ $t("message.opportunities.status") }}
                          </td>
                          <td>
                            <b-badge
                              :variant="badgeColor(opportunityData.status)"
                            >
                              {{ opportunityStatus(opportunityData.status) }}
                            </b-badge>
                          </td>
                        </tr>

                        <br />
                        <tr v-if="opportunityData.international">
                          <td class="pr-1">
                            {{ $t("message.expenses.international") }}:
                          </td>
                          <td>
                            {{ checkTrue(opportunityData.international) }}
                          </td>
                        </tr>
                        <tr v-if="opportunityData.international">
                          <td class="pr-1">
                            {{ $t("message.expenses.price") }}:
                          </td>
                          <td>{{ opportunityData.internationalPrice }}</td>
                        </tr>
                        <tr v-if="opportunityData.international">
                          <td class="pr-1">
                            {{ $t("message.expenses.Totalprice") }}:
                          </td>
                          <td>
                            {{
                              $n(
                                parseFloat(opportunityData.value) *
                                  parseFloat(
                                    opportunityData.internationalPrice
                                  ).toString(),
                                "currency"
                              )
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <b-button
                      variant="primary"
                      @click="isAddNewContractSidebarActive = true"
                      v-if="restrictions('button_new_contract')"
                    >
                      <span class="text-nowrap">{{
                        $t("message.buttons.newContract")
                      }}</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Note -->
            <b-card-body class="invoice-padding pt-0"> </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-modal
        id="modal-delete-opportunity-confirmation"
        modal-class="modal-danger"
        centered
        :title="$t('delete')"
        hide-footer
      >
        <b-card-text> Tem certeza que deseja deletar o registro ? </b-card-text>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
          <b-button
            v-b-modal.modal-danger
            class="mt-2"
            variant="danger"
            :to="{ name: 'apps-opportunity-list' }"
            @click="deleteOpportunity()"
          >
            {{ $t("message.buttons.delete") }}
          </b-button>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
  BButton,
  BRow,
  BBadge,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BFormSelect,
  BCardBody,
  BTableLite,
  BCardText,
  VBToggle,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VMoney } from "v-money";
import currency from "@/@core/utils/currency";
import Logo from "@core/layouts/components/Logo.vue";
import AccessControl from "@core/utils/access-control";
import ContractAddNew from "./ContractAddNew.vue";
import store from "@/store";

export default {
  components: {
    ContractAddNew,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BButton,
    BRow,
    BCol,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormSelect,
    vSelect,
    BCardBody,
    BTableLite,
    BCardText,
    VBToggle,
    Logo,
    BDropdown,
    BDropdownItem,
  },

  directives: {
    money: VMoney,
  },

  data() {
    return {
      customerEmails: "",
      customerPhones: "",
      customerData: [],
      customerOptions: [],
      userData: store.state.user.userData,
      opportunityData: [],

      //Select
      customerOptions: [],
      projectOptions: [],
      teamOptions: [],

      opportunityName: "",
      opportunityDescription: "",
      opportunityValue: "",
      opportunityCustomerId: "",
      opportunityDate: "",
      international: false,
      internationalPrice: "",

      isAddNewContractSidebarActive: false,
    };
  },

  async created() {
    await axios
      .get(`${URL_API}opportunity/${router.currentRoute.params.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        this.opportunityData = response.data;
        this.loadStatus();
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.opportunityData = undefined;
        }
      });

    var customerList = [];
    var customerId = [];
    await axios
      .get(`${URL_API}customer/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          customerList.push(element);
          customerId.push(id);
          this.customerOptions.push({
            label: customerList[i],
            value: customerId[i],
          });
        }
      });

    var teamList = [];
    var teamId = [];
    await axios
      .get(`${URL_API}team/select_list`, { headers: getHeader(this.userData) })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          teamList.push(element);
          teamId.push(id);
          this.teamOptions.push({ label: teamList[i], value: teamId[i] });
        }
      });

    var projectList = [];
    var projectId = [];
    await axios
      .get(`${URL_API}project/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          projectList.push(element);
          projectId.push(id);
          this.projectOptions.push({
            label: projectList[i],
            value: projectId[i],
          });
        }
      });
  },

  watch: {
    locale(val) {
      this.$i18n.locale = val;
    },
  },

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    badgeColor(value) {
      if (value === "Venceu") {
        return "success";
      }
      if (value === "Perdeu") {
        return "danger";
      }
      if (value === "Pendente") {
        return "warning";
      }
      return "warning";
    },

    checkTrue(value) {
      return this.$i18n.t("message." + value);
    },

    opportunityStatus(status) {
      if (status === "Venceu") {
        return this.$i18n.t("message.opportunities.won");
      }
      if (status === "Perdeu") {
        return this.$i18n.t("message.opportunities.lost");
      }
      if (status === "Pendente") {
        return this.$i18n.t("message.opportunities.pending");
      }
      return this.$i18n.t("message.opportunities.pending");
    },

    restrictions(value) {
      return AccessControl(value);
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    async loadStatus() {
      await axios
        .get(`${URL_API}customer/${this.opportunityData.customerId}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.customerData = response.data;
        });

      if (this.customerData.phoneNumbers) {
        this.customerPhones = this.customerData.phoneNumbers.join(", ");
        this.customerEmails = this.customerData.emailAdresses.join(", ");
      }

      this.opportunityName = this.opportunityData.name;
      this.opportunityDescription = this.opportunityData.description;
      this.opportunityValue = this.opportunityData.value;
      this.opportunityCustomerId = this.opportunityData.customerId;
      this.opportunityDate = this.opportunityData.dateCreation;
      this.international = this.opportunityData.international;
      this.internationalPrice = this.opportunityData.internationalPrice;
    },

    deleteOpportunity() {
      axios({
        method: "delete",
        url: `${URL_API}opportunity/${this.opportunityData.id}`,
        headers: getHeader(this.userData),
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.deleted"),
              icon: "CheckIcon",
              variant: "success",
              text: `A oportunidade foi deletada com sucesso`,
            },
          });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            opportunityData.value = undefined;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
